<template>
    <section class="home-title py-5">
        <div class="container">
            <h1 class="d-none d-md-block display-3 font-weight-bolder text-center mb-4 font-noto-serif-tc">
                美白 收毛孔 一take過
            </h1>
            <h1 class="d-block d-md-none display-4 font-weight-bolder text-center mb-4 font-noto-serif-tc">
                美白 收毛孔 一take過
            </h1>
            <div class="row no-gutters mb-5">
                <h1 class="d-none d-md-block col-md-6 text-center text-md-right"
                    data-aos="fade-right"
                    data-aos-duration="1000">
                    一頭3激光纖技術
                </h1>
                <h2 class="d-block d-md-none col-md-6 text-center text-md-right"
                    data-aos="fade-right"
                    data-aos-duration="1000">
                    一頭3激光纖技術
                </h2>
                <div class="col-md-6"></div>
                <div class="col-md-3 col-lg-5"></div>
                <h1 class="d-none d-md-block col-md-9 col-lg-7 text-center text-md-left"
                    data-aos="fade-left"
                    data-aos-duration="1000">
                    適合不同膚色毛髮粗幼人士
                </h1>
                <h2 class="d-block d-md-none col-md-9 col-lg-7 text-center text-md-left"
                    data-aos="fade-left"
                    data-aos-duration="1000">
                    適合不同膚色毛髮粗幼人士
                </h2>
            </div>
            <div class="row">
                <elysion-pro-comb-face class="col-6 col-md-4 col-lg-2"
                                       ear-color="#f4f1ef"
                                       face-color="#ffffff"
                                       text-color="#e37407"
                                       data-aos="fade-up"
                                       data-aos-duration="1000">
                    <h1 class="display-3 font-weight-bolder"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        I
                    </h1>
                </elysion-pro-comb-face>
                <elysion-pro-comb-face class="col-6 col-md-4 col-lg-2"
                                       ear-color="#eee8b8"
                                       face-color="#f9f5c4"
                                       text-color="#e37407"
                                       data-aos="fade-up"
                                       data-aos-duration="1000">
                    <h1 class="display-3 font-weight-bolder"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        II
                    </h1>
                </elysion-pro-comb-face>
                <elysion-pro-comb-face class="col-6 col-md-4 col-lg-2"
                                       ear-color="#ddd996"
                                       face-color="#e7e6a0"
                                       text-color="#e37407"
                                       data-aos="fade-up"
                                       data-aos-duration="1000">
                    <h1 class="display-3 font-weight-bolder"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        III
                    </h1>
                </elysion-pro-comb-face>
                <elysion-pro-comb-face class="col-6 col-md-4 col-lg-2"
                                       ear-color="#d7c56d"
                                       face-color="#e1d074"
                                       text-color="#e37407"
                                       data-aos="fade-up"
                                       data-aos-duration="1000">
                    <h1 class="display-3 font-weight-bolder"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        IV
                    </h1>
                </elysion-pro-comb-face>
                <elysion-pro-comb-face class="col-6 col-md-4 col-lg-2"
                                       ear-color="#d4ac44"
                                       face-color="#dfbb4d"
                                       text-color="#ffffff"
                                       data-aos="fade-up"
                                       data-aos-duration="1000">
                    <h1 class="display-3 font-weight-bolder"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        V
                    </h1>
                </elysion-pro-comb-face>
                <elysion-pro-comb-face class="col-6 col-md-4 col-lg-2"
                                       ear-color="#c79308"
                                       face-color="#d2a009"
                                       text-color="#fffeac"
                                       data-aos="fade-up"
                                       data-aos-duration="1000">
                    <h1 class="display-3 font-weight-bolder"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        VI
                    </h1>
                </elysion-pro-comb-face>
            </div>

            <h5 class="font-weight-normal text-justify mb-5"
                data-aos="fade-up"
                data-aos-duration="1000">
                COMB 3激光纖波長脫毛技術(810-940-1060nm) ，針對毛髮黑色素、毛囊微血管及深色皮膚人士，配合毛髮生長週期，可令毛囊逐漸萎縮，破壞毛髮再生力，脫毛效果特別顯著有效！
            </h5>
        </div>
    </section>
</template>

<script>
    import ElysionProCombFace from "./components/Face";
    export default {
        name: "ElysionProCombHome2",
        components: {
            ElysionProCombFace
        },
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .home-title {
        background-color: $background-color-alt;
        color: $text-color;
    }
</style>
