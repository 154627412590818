<template>
    <div class="face position-relative mb-5">
        <svg version="1.2" baseProfile="tiny" id="Objects" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 555.2608643 638.6087036" xml:space="preserve">
            <ellipse id="ear_right"
                     class="ear"
                     transform="matrix(0.1329095 -0.9911281 0.9911281 0.1329095 91.4567261 769.3244019)"
                     v-bind:fill="earColor"
                     cx="485.4166565"
                     cy="332.3923645"
                     rx="77.9543076"
                     ry="48.1013489">
            </ellipse>
            <path id="ear_left"
                  class="ear"
                  v-bind:fill="earColor"
                  d="M117.3444977,317.9379272
                c8.4498444,42.0660095-5.4413528,82.6384888-31.026207,90.6216736
                c-25.5855179,7.9831848-53.1767006-19.646759-61.6265411-61.7127991s5.4406834-82.6384888,31.0262051-90.6216736
                S108.8939896,275.8718872,117.3444977,317.9379272z">
            </path>
            <path class="face"
                  v-bind:fill="faceColor"
                  d="M503.0180664,272.6795654c0,156.3083496-120.5515442,343.1903076-222.8508301,343.1903076
                S57.3164673,428.987915,57.3164673,272.6795654s99.7734985-249.5928955,222.850769-249.5928955
                S503.0180664,116.3712006,503.0180664,272.6795654z">
            </path>
            <path fill="none"
                  stroke="#e5790a"
                  stroke-width="20"
                  stroke-miterlimit="10"
                  d="M280.167572,625.869873
                c-37.772934,0-78.4233246-22.3935547-117.5566254-64.7607422c-34.480957-37.3310547-64.7016602-87.2304688-85.59375-141.1865234
                c-28.2353516-0.234375-53.753418-29.4082031-62.1293945-71.1064453
                c-9.0854492-45.2314453,5.4223642-87.9404297,33.2480469-100.3940582
                c4.6567383-69.4697266,29.4448242-128.4389648,71.9077148-170.8764648
                c41.5922852-41.5668983,98.4584961-64.4589844,160.1240082-64.4589844c61.8554688,0,118.8486328,23.0170898,160.4804688,64.8110352
                c42.6855469,42.8520508,67.4082031,102.4370041,71.6914368,172.6108398
                c11.7822266,6.8393707,21.09375,19.5263824,26.5869141,36.4375153c5.9970703,18.4599609,6.9277344,40.4326172,2.6210938,61.8701172
                c-7.9785156,39.7177734-31.5127258,68.0693359-58.1357727,70.8662109
                c-20.9335938,54.1455078-51.2685547,104.2236328-85.8642578,141.6181641
                C358.4683533,603.541748,317.8785095,625.869873,280.167572,625.869873z">
            </path>
        </svg>
        <div class="position-absolute overlay-content"
             v-bind:style="{ 'color': textColor }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ElysionProCombFace",
        props: {
            earColor: {
                type: String,
                default: "#F2EAE2",
            },

            faceColor: {
                type: String,
                default: "#FFFFFF",
            },

            textColor: {
                type: String,
            },
        },
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    .face {
        .overlay-content {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            z-index: 1;
        }
    }
</style>
